import React from 'react';
import { 
  Collapse,
  Button,
  CardBody,
  Card,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import locales from '../../constants/locales';
import LocalizedLink from '../LocalizedLink';

const SectionMenu = ({
  section,
  toggle,
  collapse,
  collapseSection,
  UnDeploySvg,
  DeploySvg
}) => (
  <div>
    <Button
      className="positiveButton"
      onClick={() => toggle(section.id)}
    >
      <img 
        alt="Deploy"
        src={collapseSection(section.id, collapse)? UnDeploySvg : DeploySvg} 
        className="material-icons d-inline-block align-top" 
      />
      <FormattedMessage 
        id={section.id}
        defaultMessage={section.defaultMessage}
      />
    </Button>
    <Collapse isOpen={collapseSection(section.id, collapse)}>
      <ul className="applications-menu">
        {
          section.menu.map(item => (
            <li className="sub-menu">
              <LocalizedLink to={item.to}>
                <FormattedMessage 
                  id={item.id}
                  defaultMessage={item.defaultMessage}
                />
              </LocalizedLink>
            </li>
          ))
        }
      </ul>
    </Collapse>
  </div>
);

export default SectionMenu;
