import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container } from "reactstrap";
import { FormattedMessage } from 'react-intl';

import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar';
import LocalizedLink from '../components/LocalizedLink';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import HeadMeta from '../components/HeadMeta';

const metaData = {
  en: {
    title: `TriDyme | Documentation`,
    pageTitle: "Documentation",
    description: "Documentation about TriDyme plateforme"
  },
  fr: {
    title: `TriDyme | Documentation`,
    pageTitle: "Documentation",
    description: "Documentation sur la plateforme TriDyme"
  }
};

const Documentation = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <HeadMeta
      locale={locale}
      metaData={metaData}
    />
    {/* {data.allMarkdownRemark.edges} */}

  </Layout>
)

Documentation.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default Documentation;

export const query = graphql`
  query {
    allMarkdownRemark(limit: 1) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

