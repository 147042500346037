import React from 'react';
import PropTypes from 'prop-types';
import { 
  Collapse,
  Button,
  CardBody,
  Card,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import locales from '../../constants/locales';
import LocalizedLink from '../LocalizedLink';
import DeploySvg from './img/deploy.svg';
import UnDeploySvg from './img/undeploy.svg';

import SectionMenu from '../SectionMenu';

class SideBar extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.toggle = this.toggle.bind(this);
  //   this.collapseSection = this.collapseSection.bind(this);
  //   this.state = { collapse: '' };
  // }
  // toggle(menu) {
  //   if(menu === this.state.collapse) {
  //     this.setState({ collapse: '' });
  //   } else {
  //     this.setState({ collapse: menu });
  //   }
  // }

  // collapseSection(section, collapse) {
  //   if(section === collapse) {
  //     return true;
  //   }
  //   return false;
  // }

  render() {
    const { 
      intl: {locale},
      toggle,
      collapseSection,
      collapse
    } = this.props;
    // const { 
    //   collapse
    // } = this.state;
    const documentationMenu = {
      gettingStarted: {
        introduction: {
          id: 'documentation.introduction',
          defaultMessage: 'Introduction',
          to: `/documentation${locales[locale].path}/presentation/introduction`,
          menu: [
            {
              id: 'documentation.getting-started.introduction',
              defaultMessage: 'Introduction',
              to: `/documentation/${locales[locale].path}/presentation/introduction/`
            }
          ]
        }
      },
      applications: {
        structure3d: {
          id: 'documentation.structure3d',
          defaultMessage: 'Structure 3D',
          to: `/documentation/${locales[locale].path}/structure3d/getting-started`,
          menu: [
            {
              id: 'documentation.structure3d.getting-started',
              defaultMessage: 'Getting Started',
              to: `/documentation/${locales[locale].path}/structure3d/getting-started/`
            },
            {
              id: 'documentation.structure3d.materials',
              defaultMessage: 'Materials',
              to: `/documentation/${locales[locale].path}/structure3d/materials`
            },
            {
              id: 'documentation.structure3d.sections',
              defaultMessage: 'Sections',
              to: `/documentation/${locales[locale].path}/structure3d/sections`
            },
            {
              id: 'documentation.structure3d.supports',
              defaultMessage: 'Supports',
              to: `/documentation/${locales[locale].path}/structure3d/supports`
            },
            {
              id: 'documentation.structure3d.nodes',
              defaultMessage: 'Nodes',
              to: `/documentation/${locales[locale].path}/structure3d/nodes`
            },
            {
              id: 'documentation.structure3d.members',
              defaultMessage: 'Members',
              to: `/documentation/${locales[locale].path}/structure3d/members`
            },
            {
              id: 'documentation.structure3d.nodeloads',
              defaultMessage: 'Node Loads',
              to: `/documentation/${locales[locale].path}/structure3d/nodeloads`
            },
            {
              id: 'documentation.structure3d.analysis',
              defaultMessage: 'Analysis',
              to: `/documentation/${locales[locale].path}/structure3d/analysis`
            }
          ]
        },
        concreteSection: {
          id: 'documentation.concretesection',
          defaultMessage: 'Concrete Section',
          to: `/documentation/${locales[locale].path}/structure3d/`,
          menu: [
            // {
            //   id: 'documentation.structure3d.analysis',
            //   defaultMessage: 'Analysis',
            //   to: `/documentation/${locales[locale].path}/structure3d/materials`
            // }
          ]
        },
        steelSection: {
          id: 'documentation.steelsection',
          defaultMessage: 'Steel Section',
          to: `/documentation/${locales[locale].path}/structure3d/`,
          menu: []
        }
      },
      other: {
        starterKit: {
          id: 'documentation.developpers',
          defaultMessage: 'Developpers',
          to: `/documentation/${locales[locale].path}/developpers/tridyme-webapp-kit-serverless`,
          menu: [
            {
              id: 'documentation.tridyme-webapp-kit-serverless',
              defaultMessage: 'tridyme-webapp-kit-serverless',
              to: `/documentation/${locales[locale].path}/developpers/tridyme-webapp-kit-serverless`
            },
          ]
        }
      }
    }
    return (
      <div className="sidebar">
        <h2>
          <FormattedMessage
            id="documentation.getting-sarted"
            defaultMessage="Getting Started"
          />
        </h2>
        <ul>
          <li>
            <SectionMenu 
              section={documentationMenu.gettingStarted.introduction}
              toggle={toggle}
              collapse={collapse}
              collapseSection={collapseSection}
              UnDeploySvg={UnDeploySvg}
              DeploySvg={DeploySvg}
            />
          </li>
        </ul>
        <hr />
        <h2>Applications</h2>
        <ul>
          <li>
            <SectionMenu 
              section={documentationMenu.applications.structure3d}
              toggle={toggle}
              collapse={collapse}
              collapseSection={collapseSection}
              UnDeploySvg={UnDeploySvg}
              DeploySvg={DeploySvg}
            />
          </li>
          <li>
            <SectionMenu 
              section={documentationMenu.applications.concreteSection}
              toggle={toggle}
              collapse={collapse}
              collapseSection={collapseSection}
              UnDeploySvg={UnDeploySvg}
              DeploySvg={DeploySvg}
            />
          </li>
          <li>
            <SectionMenu 
              section={documentationMenu.applications.steelSection}
              toggle={toggle}
              collapse={collapse}
              collapseSection={collapseSection}
              UnDeploySvg={UnDeploySvg}
              DeploySvg={DeploySvg}
            />
          </li>
        </ul>
        <hr />
        <h2>          
          <FormattedMessage
            id="documentation.other"
            defaultMessage="Other"
          />
        </h2>
        <ul>
          <li>
            <SectionMenu 
              section={documentationMenu.other.starterKit}
              toggle={toggle}
              collapse={collapse}
              collapseSection={collapseSection}
              UnDeploySvg={UnDeploySvg}
              DeploySvg={DeploySvg}
            />
          </li>
        </ul>
      </div>
    );
  }
}

SideBar.propTypes = { intl: intlShape.isRequired }

export default injectIntl(SideBar);
